import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetSetAtributosService {
  private data_vendedor:any;
  private _parametro: any;
  private _producto: any;
  private _chat_data: any;
  
  public get chat_data(): any {
    return this._chat_data;
  }
  public set chat_data(value: any) {
    this._chat_data = value;
  }
  
  public get producto(): any {
    return this._producto;
  }
  public set producto(value: any) {
    this._producto = value;
  }
  
  get parametro(): any {
    return this._parametro;
  }
  set parametro(value: any) {
    this._parametro = value;
  }
  constructor() { }

  get data_vendedor_value(){
    return this.data_vendedor;
  }
  set data_vendedor_value(xobjeto){
        this.data_vendedor = xobjeto;
  }
}
