import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import {base64ToFile} from 'ngx-image-cropper';
import { skServices } from './sk.services.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  /* url1:any=`${environment.URL}/listarProductos`
  url2:any=`${environment.URL}/buscarProdDeposito` */

  constructor(
    private database: AngularFirestore, 
    private Http: HttpClient, 
    public storage: AngularFireStorage,
    private skservice: skServices,
    ) {}

  /*  listarProductos():Observable<any>{

    return this.Http.get(this.url1)

  }
  buscarProdDeposito(path:any,id:string):Observable<any>{

    var body = {id}

    return this.Http.post(this.url2,body)

  } */

  CargarImagen(file: any, path: string, nombre: string): Promise<string> {
    return new Promise((resolve) => {
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      const task = ref.put(file);
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe((result) => {
              const downloadURL = result;
              resolve(downloadURL);
              return;
            });
          })
        )
        .subscribe();
    });
  }
  CargarImagenBase64(base64: any, path: string): Promise<string> {
    return new Promise((resolve) => {
      const nombre = this.database.createId()
      const filePath = path + '/' + nombre;
      const ref = this.storage.ref(filePath);
      const task = ref.put(base64ToFile(base64));
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe((result) => {
              const downloadURL = result;
              resolve(downloadURL);
              return;
            });
          })
        )
        .subscribe();
    });
  }

  CrearProductos(data: any, path: string, Id: string) {
    return this.database.collection(path).doc(Id).set(data);
  }

  EliminarProductos(path: any, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).delete();
  }

  ActualizarProductos(data: any, path: any, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).update(data);
  }

  CrearID() {
    return this.database.createId();
  }

  ConsultarProducto(path: any, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).valueChanges();
  }

  /* ConsultarProductos<tipo>(path:any):Observable<any> {
    return this.Http.get(this.url1)
  } */

  ConsultarDataQuery<tipo>(
    path: any,
    parametro: string,
    condicion: any,
    busqueda: string
  ) {
    const collection = this.database.collection<tipo>(path, (ref) =>
      ref.where(parametro, condicion, busqueda)
    );
    return collection.valueChanges();
  }

  ConsultarPedidosTodos<tipo>(path) {
    const date = new Date();
    const collection = this.database.collection<any>(
      path
      /* ref => ref.where(parametro, condicion, busqueda)
                .orderBy('fecha','desc')
                .limit(5)
                .startAt(date) */
    );
    return collection.valueChanges();
  }

  ConsultarProductosMarcas<tipo>(
    path,
    parametro: string,
    condicion: any,
    busqueda: string
  ) {
    const date = new Date();
    const collection = this.database.collectionGroup<tipo>(
      path,
      (ref) => ref.where(parametro, condicion, busqueda)
      /*  .orderBy('fecha','desc') */
      /* .limit(5)  */
      /* .startAt(date) */
    );
    return collection.valueChanges();
  }

  ConsultarProductosTop<tipo>(
    path,
    parametro: string,
    condicion: any,
    busqueda: string
  ) {
    const date = new Date();
    const collection = this.database.collectionGroup<tipo>(
      path,
      (ref) => ref.where(parametro, condicion, busqueda)
      /*  .orderBy('fecha','desc') */
      /* .limit(5) */
      /* .startAt(date) */
    );
    return collection.valueChanges();
  }
  CargarImagenPerfil(xobjeto: any): Promise<string> {
    return new Promise((resolve) => {
      const filePath = 'img/' + xobjeto.name;
      const ref = this.storage.ref(filePath);
      const task = ref.put(xobjeto);
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe((result) => {
              const downloadURL = result;
              resolve(downloadURL);
              return;
            });
          })
        )
        .subscribe();
    });
  }
  CargarImgCrop(xobjeto: any, xvalor:any, param:any): Promise<string> {
    return new Promise((resolve) => {
      console.log(xobjeto);
      const filePath = 'img/' + param +xvalor.name;
      const ref = this.storage.ref(filePath);
      const task = ref.put(xobjeto);
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe((result) => {
              const downloadURL = result;
              resolve(downloadURL);
              return;
            });
          })
        )
        .subscribe();
    });
  }
}
