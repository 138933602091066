import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';

@Component({
  selector: 'app-form-billetera',
  templateUrl: './form-billetera.component.html',
  styleUrls: ['./form-billetera.component.scss'],
})
export class FormBilleteraComponent implements OnInit {
um_total:any = 0.00;
um_bloqueado:any = 0.00;
um_disponible:any = 0.00;
um_espera:any = 0.00;
forma:FormGroup;
  constructor(
    private modalController: ModalController,
		private fb:FormBuilder,
		private uiKit: UiKitController,
		private util: UiKitController,
    private skservice: skServices,
  ) { 
    this.forma = this.fb.group({
      um_disponible: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.getBilleteraVendedor();
  }

  async getBilleteraVendedor(){
      try {
        await this.util.loading();
       var xvalor = {
          cod_usuario:localStorage.getItem('cod_usuario')
        }
        const response = await this.skservice.getBilleteraVendedorhttp(xvalor).toPromise();
        console.log(response);
        this.um_total = parseFloat(response.valor[0].um_total) ;
        this.um_bloqueado =  parseFloat(response.valor[0].um_bloqueado);
        this.um_disponible =  parseFloat(response.valor[0].um_disponible);
        this.um_espera =  parseFloat(response.valor[0].um_espera);
      } catch (error) {
        console.log(error);
      }
  }
  async dismissModal() {
		await this.util.loading();
		this.modalController.dismiss(null);
	  }
  
    ponerPuntosyComas(event:any, id:any){

      let validacion = '1234567890'
      console.log(event.key)
  
      let inputCantidad = document.querySelector(`#${id}`) as HTMLInputElement
  
  
      if(id != 'cant_ingresada'){
  
        if(inputCantidad.value.length == 1){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
          
        }else if(inputCantidad.value.length == 3){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
        }else if(inputCantidad.value.length >= 4 ){
          let noa:any = inputCantidad.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputCantidad.value = noa.join('')
        }
    
        var num = inputCantidad.value.replace(/\D/g,'');
    
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
        num = num.split('').reverse().join('').replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1.$2')
        inputCantidad.value = num;
  
      }else{
  
          if(inputCantidad.value.length == 1){
            let noa = inputCantidad.value.split('')
            noa.unshift('0')
            noa.unshift('0')
            noa.unshift('0')
            inputCantidad.value = noa.join('')
            
          }else if(inputCantidad.value.length == 4){
            let noa = inputCantidad.value.split('')
            noa.unshift('0')
            inputCantidad.value = noa.join('')
          }else if(inputCantidad.value.length >= 5 ){
            let noa:any = inputCantidad.value.split('')
            for(let i = 0 ; i < 2; i++){
              if(noa[0] == 0){
                noa.shift()
              }else{
                break;
              }
            }
            inputCantidad.value = noa.join('')
          }
      
          var num = inputCantidad.value.replace(/\D/g,'');
      
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          
          num = num.split('').reverse().join('').replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1.$2')
          inputCantidad.value = num;
  
        
      }
  
      if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
  
        this.util.ToastWarning('Only numbers');
      
      }
  
  
    }
}
