import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-login-usuarios',
  templateUrl: './form-login-usuarios.component.html',
  styleUrls: ['./form-login-usuarios.component.scss'],
})
export class FormLoginUsuariosComponent implements OnInit {

  @Input() data: any
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
dialogSpin:any;
accion:any;
unidad:any;
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
asociar:any = 'N/A';
actualizar:any = false;

  constructor(
    public fb:FormBuilder,
    private skservice: skServices,
    private loadindControl: LoadingController,
    private util: UiKitController,
    public modalController: ModalController,
    ) {
      this.forma = this.fb.group({
        correo: ['', [Validators.required, Validators.email]],
        pass: ['', [Validators.required]],
        tipo: this.data,
      });
    }

  ngOnInit(): void {
  }

 async modalrespuesta(){
    try {
      this.forma.value.tipo = this.data;
    await this.util.loading();
    this.modalController.dismiss(this.forma.value);
    this.forma.reset();
    } catch (error) {
      console.log(error);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
  }

}
