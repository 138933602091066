import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-video-muestra',
  templateUrl: './form-video-muestra.component.html',
  styleUrls: ['./form-video-muestra.component.scss'],
})
export class FormVideoMuestraComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
