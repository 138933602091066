import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StatusController } from 'src/app/services/statuscontroller.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { environment } from 'src/environments/environment';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';


@Component({
  selector: 'app-form-registro-miembro',
  templateUrl: './form-registro-miembro.component.html',
  styleUrls: ['./form-registro-miembro.component.scss'],
})
export class FormRegistroMiembroComponent implements OnInit {
  @Input() data: string
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
forma2:FormGroup;
dialogSpin:any;
accion:any;
unidad:any;
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
asociar:any = 'N/A';
actualizar:any = false;

data_usuario:any;
  categoria:any;
  definiciones:any = [];
  precio_categoria:any = '0';
  precio_categoriasm:any = '0';
  usuario_email:any;
  cod_img:any;
  card:any;
  stripe: Stripe;
  stripesm: Stripe;

  cod_tdc: any;
	cod_fecha: any;
	cod_cvc: any;

  cod_tdcsm: any;
	cod_fechasm: any;
	cod_cvcsm: any;
  

  constructor(
    public fb:FormBuilder,
    private skservice: skServices,
    private loadindControl: LoadingController,
    private util: UiKitController,
    public modalController: ModalController,
    public userControl: AuthGuardServicesService,
    private store: StoreService,
    public menucontroler: MenuController,
		public firestoreService: StoreService,
		public carritoService: CarritoService,
		public firebaseauthService: AuthGuardServicesService,
		public loadingController: LoadingController,
		public router: Router,
    private statusControl: StatusController,
    private alertController: AlertController,
    ) {
      this.data_usuario = localStorage.getItem('des_usuario');
    
    this.usuario_email = localStorage.getItem('user');
    this.cod_img = localStorage.getItem('cod_img');
      
    this.forma = this.fb.group({
      numero: [''],
      categoria: ['',[Validators.required]],
      cardCvc: [false, [Validators.required, Validators.requiredTrue]],
      cardExp: [false, [Validators.required, Validators.requiredTrue]],
      cardNumber: [false, [Validators.required, Validators.requiredTrue]],
      nom_usuario: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
      tipo: [''],
      cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
        ],
      ],
    });

    this.forma2 = this.fb.group({
      numero: [''],
      categoria: ['',[Validators.required]],
      cardCvcsm: [false, [Validators.required, Validators.requiredTrue]],
      cardExpsm: [false, [Validators.required, Validators.requiredTrue]],
      cardNumbersm: [false, [Validators.required, Validators.requiredTrue]],
      nom_usuario: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
      tipo: [''],
      cpass: ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass' }),
        ],
      ],
    });
    }

  ngOnInit(): void {
    this.categoria = this.data;
    this.card = true;
    this.cargarDefi(this.categoria);
  }
  ionViewDidEnter(){
    this.cargarElementStripe();
    this.cargarElementStripesm();
  }
  async tdcActiva(){
    try {
     await this.util.loading();
      var xvalor = {
        cod_usuario:this.data_usuario
      }
      const response = await this.skservice.getTdcUsuarioActivahttp(xvalor).toPromise();
      if (parseInt(response.tdc[0].resul) > 0 ) {
        this.card = false;
      }else{
        this.card = true;
      }
      
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarElementStripe(){
    try {
      const card1 = document.querySelector('#card-2') as HTMLDivElement;
      console.log(card1);
		if (card1) {
			this.stripe = await loadStripe(environment.stripeKey);
			const elements = this.stripe.elements();

			const style = {
				base: {
					iconColor: '#0ccde4',
					fontSize: '20px',
					color:'#212529',
				},
				invalid: {
					color: '#dc3545',
				},
			};

			const cardNumber = elements.create('cardNumber', {
				style,
				placeholder: 'Card',
				classes: {
					base: 'input-excep',
				},
			});
			const cardExpiry = elements.create('cardExpiry', {
				style,
				placeholder: 'Exp',
				classes: {
					base: 'input-excep',
				},
			});
			const cardCvc = elements.create('cardCvc', {
				style,
				placeholder: 'CVC',
				classes: {
					base: 'input-excep',
				},
			});

			cardNumber.mount('#card-2');
			cardExpiry.mount('#exp-1');
			cardCvc.mount('#cvc-1');

			this.cod_tdc = cardNumber;
			this.cod_fecha = cardExpiry;
			this.cod_cvc = cardCvc;

			this.cod_tdc.on('change', this.onChangeCard.bind(this));
			this.cod_fecha.addEventListener('change', this.onChangeCvc.bind(this));
			this.cod_cvc.on('change', this.onChangeExp.bind(this));
		}
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarElementStripesm(){
    try {
      const card1 = document.querySelector('#card-2-sm') as HTMLDivElement;
      console.log(card1);
		if (card1) {
			this.stripesm = await loadStripe(environment.stripeKey);
			const elements = this.stripesm.elements();

			const style = {
				base: {
					iconColor: '#0ccde4',
					fontSize: '20px',
					color:'#212529',
				},
				invalid: {
					color: '#dc3545',
				},
			};

			const cardNumbersm = elements.create('cardNumber', {
				style,
				placeholder: 'Card',
				classes: {
					base: 'input-excep',
				},
			});
			const cardExpirysm = elements.create('cardExpiry', {
				style,
				placeholder: 'Exp',
				classes: {
					base: 'input-excep',
				},
			});
			const cardCvcsm = elements.create('cardCvc', {
				style,
				placeholder: 'CVC',
				classes: {
					base: 'input-excep',
				},
			});

			cardNumbersm.mount('#card-2-sm');
			cardExpirysm.mount('#exp-1-sm');
			cardCvcsm.mount('#cvc-1-sm');

			this.cod_tdcsm = cardNumbersm;
			this.cod_fechasm = cardExpirysm;
			this.cod_cvcsm = cardCvcsm;

			this.cod_tdcsm.on('change', this.onChangeCardsm.bind(this));
			this.cod_fechasm.addEventListener('change', this.onChangeCvcsm.bind(this));
			this.cod_cvcsm.on('change', this.onChangeExpsm.bind(this));
		}
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

  async enviarStripe() {
		try {
      await this.util.loading();
      const { token } = await this.stripe.createToken(this.cod_tdc);
      if (token) {
        let xvalor = {
          amount: parseFloat(this.forma.value.categoria.val_definicion),
          currency:'usd',
          token:token.id
        }
        this.skservice.getPagosPedidohttp(xvalor).subscribe(
          response=>{
            console.log(response.pago.status,'stripe_payment');
            this.modalrespuesta();
          },
          error=>{
            console.log(error);
            this.util.ToastError(error.error.message);
          }
        ); 
      }else{
        await this.util.ToastError('There was a problem making the payment');
      }
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
	}
  async enviarStripesm() {
		try {
      await this.util.loading();
      const { token } = await this.stripesm.createToken(this.cod_tdcsm);
      if (token) {
        let xvalor = {
          amount: parseFloat(this.forma2.value.categoria.val_definicion),
          currency:'usd',
          token:token.id
        }
        this.skservice.getPagosPedidohttp(xvalor).subscribe(
          response=>{
            console.log(response.pago.status,'stripe_payment');
            this.modalrespuestasm();
          },
          error=>{
            console.log(error);
            this.util.ToastError(error.error.message);
          }
        ); 
      }else{
        await this.util.ToastError('There was a problem making the payment');
      }
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
	}
  async cargarDefi(xval:any){
    try {
      var xvalor = {
        des_definicion:'categorias',
        concepto_definicion:xval.toLowerCase()
      }
      console.log(xvalor);
      const response = await this.skservice.getDefinicionSitemathttp(xvalor).toPromise();
      console.log(response);
        if (this.categoria.toLowerCase() === 'basic') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'basic') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'silver') {
          for (const i in response.def) {
            console.log(response.def[i].concepto_definicion.toLowerCase());
            if (response.def[i].concepto_definicion.toLowerCase() === 'silver') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'gold') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'gold') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        if (this.categoria.toLowerCase() === 'platinum') {
          for (const i in response.def) {
            if (response.def[i].concepto_definicion.toLowerCase() === 'platinum') {
              this.definiciones.push(response.def[i]);
            }
          }
        }
        console.log(this.definiciones);
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarPrecioCategoria(xval:any){
    try {
      console.log(this.forma.value.categoria);
      await this.util.loading();
     this.precio_categoria = this.forma.value.categoria.val_definicion;
     
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarPrecioCategoriasm(xval:any){
    try {
      console.log(this.forma.value.categoria);
      await this.util.loading();
     this.precio_categoriasm = this.forma2.value.categoria.val_definicion;
     
    } catch (error) {
      console.log(error);
      
      await this.util.ToastWarning(error.error.message);
    }
  }

  onChangeCard({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardNumber: !error });
	}
	onChangeCvc({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardCvc: !error });
	}
	onChangeExp({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardExp: !error });
	}
  onChangeCardsm({ error }: any) {
		console.log(error, this.forma2);
		this.forma2.patchValue({ cardNumbersm: !error });
	}
	onChangeCvcsm({ error }: any) {
		console.log(error, this.forma2);
		this.forma2.patchValue({ cardCvcsm: !error });
	}
	onChangeExpsm({ error }: any) {
		console.log(error, this.forma2);
		this.forma2.patchValue({ cardExpsm: !error });
	}
 async modalrespuesta(){
    try {
      this.forma.value.categoria = this.data.toLowerCase();
      this.forma.value.tipo = 'MMBR';
    await this.util.loading();
    this.modalController.dismiss(this.forma.value);
    this.forma.reset();
    } catch (error) {
      console.log(error);
    }
  }
  async modalrespuestasm(){
    try {
      this.forma2.value.categoria = this.data.toLowerCase();
      this.forma2.value.tipo = 'MMBR';
    await this.util.loading();
    this.modalController.dismiss(this.forma2.value);
    this.forma2.reset();
    } catch (error) {
      console.log(error);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
  }
}
