import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
import {IonContent, LoadingController, ModalController} from '@ionic/angular';
import iziToast from 'izitoast';
import { skServices } from 'src/app/services/sk.services.service';
import { FormRegistroMiembroComponent } from 'src/app/componentes/shared/modal/form-registro-miembro/form-registro-miembro.component';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { id } from '@swimlane/ngx-datatable';
import { FormLoginUsuariosComponent } from 'src/app/componentes/shared/modal/form-login-usuarios/form-login-usuarios.component';
import { FormLoginProveedorComponent } from 'src/app/componentes/shared/modal/form-login-proveedor/form-login-proveedor.component';
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { StatusController } from 'src/app/services/statuscontroller.service';
import { Usuario } from 'src/models';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RouterService } from 'src/app/services/router.service';
import { FormNivelComponent } from '../modal/form-nivel/form-nivel.component';
import { FormPagoMembresiaComponent } from '../modal/form-pago-membresia/form-pago-membresia.component';


SwiperCore.use([Pagination]);

@Component({
  selector: 'app-home-membresia-visitante',
  templateUrl: './home-membresia-visitante.component.html',
  styleUrls: ['./home-membresia-visitante.component.scss'],
})
export class HomeMembresiaVisitanteComponent implements OnInit {

  @ViewChild(IonContent, { static: false }) content: IonContent;
  tipoUsuario: string;
  fecha:any = new Date().getFullYear();
  xfecha = this.fecha;
  categoria: any = localStorage.getItem('usuario_tipo');
  db = firebase.firestore();
  path = 'Clientes';
  config: SwiperOptions = {
    autoplay: true,
    keyboard: true,
    loop: true,
    slidesPerView: 3,
    spaceBetween: 10,
    pagination: true,
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        spaceBetween: 3,
        slidesPerView: 3.2,
      },
    },
  };
  usuarioData: Usuario = JSON.parse(localStorage.getItem('userData')) || {
    userId: '',
    email: '',
    displayName: '',
    nombre: '',
    apellido: '',
    genero: '',
    fecha_nacimiento: '',
    edad: '',
    descripcion: '',
    photoURL: '',
    telefono: '',
    direccion: {
      pais: '',
      dir_str: '',
      location: {
        lat: '',
        long: '',
      },
    },
  };
  usuarioDataSub$ = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userData')));
  constructor
    (
            private autho: AuthGuardServicesService,
            private skservice: skServices,
            private modalController: ModalController,
            private loadindControl: LoadingController,
            private statusControl: StatusController,
            private router: Router,
            private util: UiKitController,
            public auth: AngularFireAuth,
            private navigate: RouterService,
    ) {}

  ngOnInit() {}

  async registro_miembros(xvalor: any) {
    try {
      const auth: Auth = getAuth();
      var silver = 's';
    var gold = 'g';
    var platinum = 'p';
    var tipo;
    if (xvalor.toUpperCase() === silver.toUpperCase()) {
      tipo = 'Silver';
    }
    if (xvalor.toUpperCase() === gold.toUpperCase()) {
      tipo = 'Gold';
    }
    if (xvalor.toUpperCase() === platinum.toUpperCase()) {
      tipo = 'Platinum';
    }
    await this.util.loading();
    const modal = await this.modalController.create({
      component: FormRegistroMiembroComponent,
      cssClass: 'my-custom-class',
      componentProps: { data:tipo},
      backdropDismiss: false
    });
    await modal.present();
    const { data } = await modal.onWillDismiss()
    if (data !== undefined && data !== null && data !== '') {
      const response = await this.skservice.crearusuariohttp(data).toPromise();
      if (response.correo) {
        const data_cloud =  await createUserWithEmailAndPassword(auth,response.correo[0].correo, data.pass);
        
        let data_clo = {
          id_cloud:data_cloud.user.uid,
          correo:response.correo[0].correo
        }
        console.log(data_clo);
        await this.skservice.getUpdateIdcloudhttp(data_clo).toPromise();
        
        data.id_cloud = data_cloud.user.uid;
         await updateProfile(auth.currentUser, { displayName: data.nom_usuario });
         await (await this.auth.currentUser).sendEmailVerification();
         this.statusControl.signOut()
          localStorage.clear();
            this.router.navigate(['']).then(() => {
              /* location.reload() */
              
              alert(response.message);
            })
         
      }else{
        alert("Your internet connection is failing");
      }
    }
    return modal;
    } catch (error) {
      console.log(error);
      await this.util.ToastError(error.error.message);
    }
  }
 async mantenimiento(){
    await this.util.loading();
    await this.util.ToastWarning('This profile is under maintenance');
    await this.util.closeLoading();
  }
  async salir(xvalor:any){
    try {
      console.log(xvalor);
      await this.util.loading();
        await this.navigate.rutas_app(xvalor);
      await this.util.closeLoading();
      
    } catch (error) {
      console.log(error);
    }
  }

}
