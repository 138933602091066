import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import * as moment from 'moment';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Productos, Usuario } from 'src/models';
import { SwiperOptions } from 'swiper';



@Component({
  selector: 'app-modal-productos',
  templateUrl: './modal-productos.component.html',
  styleUrls: ['./modal-productos.component.scss'],
})
export class ModalProductosComponent implements OnInit {
  @Input() data: any;
  galeria:any;
  path:any;
  usuario: Usuario;
  NuevaImagen:any = '';
  NuevoArchivo:any = '';
  validacion: any;
  objectURL:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';
  croppedImage:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';
  croppedImage_1:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';

  imageChangedEvent: any = '';
  
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  // variables para experiencia de usuario

  PermitirNuevoProducto = false;
  loading: any;
  img:any = '';
  img_count:any = [];
  img_prod:any = '';
  img_prod_all:any = '';
  forma: FormGroup;
  forma2: FormGroup;
  rows:any = [];
  indexCard: any;

  config2: SwiperOptions = {
    initialSlide: 1,
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 10,
    effect: 'slide',
  };

  Images2: any = [
    {
      activate: 'like',
    },
    {
      activate: 'null',
    },
    {
      activate: 'dislike',
    },
  ];

  config: SwiperOptions = {
    autoplay: true,
    keyboard: true,
    slidesPerView: 1,
    pagination:true,
    navigation:true,
    spaceBetween: 10,
    scrollbar: { draggable: true },
  };
  nombre_img: any;
  param_img: any;

  constructor(
    public modalController: ModalController,
    public userProductControl: UserService,
    public firestoreService: StoreService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public fb: FormBuilder,
    public userControl: AuthGuardServicesService,
    private loadindControl: LoadingController,
    private util: UiKitController,
    private store: StoreService,
    private skservice: skServices,
  ) {}
  get title(): any{
    return this.forma.get('title')
  }

  ngOnInit() {
    this.galeria = this.data.galeria;
    console.log(this.data);
    this.img_prod_all = this.data.cod_img;
    if (this.galeria === '0') {
      this.croppedImage_1 = this.data.cod_img;
      
    }else{
      this.listarGaleria(this.data);
    }

    this.usuario = this.userControl.Usuario

    this.path = `Productos/`
    this.forma = this.fb.group({
      user_id: this.usuario.userId,
      user_name: this.usuario.nombre,
      title: [''],
      price: [''],
      descripcion: ['',],
      tipo_producto: [this.data.tipo_producto,[Validators.required, Validators.maxLength(30)]],
      cant_producto: ['1',Validators.required],
      des_producto: [this.data.des_producto,[Validators.required, Validators.maxLength(30)]],
      precio_producto: [this.data.um_producto,Validators.required],
      cat_producto: [''],
      operacion: [this.data.operacion],
      cod_producto: [this.data.cod_producto],
      img: [''],
      alt:''
    })
    this.forma2 = this.fb.group({
      user_id: this.usuario.userId,
      user_name: this.usuario.nombre,
      title: [''],
      price: [''],
      descripcion: ['',],
      tipo_producto: [this.data.tipo_producto],
      cant_producto: ['1'],
      des_producto: [this.data.des_producto],
      precio_producto: [this.data.um_producto],
      cat_producto: [''],
      operacion: [this.data.operacion],
      cod_producto: [this.data.cod_producto],
      img: [''],
      alt:''
    })
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.objectURL);
  }

  async modalrespuesta() {
    if(this.forma.invalid){

      console.log(this.forma.value)

      this.presentToast('Missing fields', 'danger')

      return

    }
    try {
      await this.presentLoading();

      console.log(this.forma.value)

      if (this.data) {
        const nombre = this.forma.value.title;
        if (this.NuevoArchivo) {
         /*  const result = await this.firestoreService.CargarImagen(
            this.NuevoArchivo,
            path,
            nombre
          );
          this.forma.patchValue({src:result}) */
          this.forma.patchValue({src:this.NuevaImagen})
        }
        this.forma.patchValue({alt:`${this.usuario.userId}, ${this.usuario.nombre +' '+this.usuario.apellido}, ${this.forma.value.title}` })
        this.userProductControl.ActualizarProductosUsuario(this.forma.value)
        this.loading.dismiss();
        this.presentToast('Saved with success', 'success');
      } else {
        const nombre = this.forma.value.title;
       /*  const result = await this.firestoreService.CargarImagen(
          this.NuevoArchivo,
          path,
          nombre
        ); */

        /* this.forma.patchValue({src:result}) */
        console.log(this.NuevaImagen)
        this.forma.patchValue({src:this.NuevaImagen})
        this.forma.patchValue({alt:`${this.usuario.userId}, ${this.usuario.nombre +' '+this.usuario.apellido}, ${this.forma.value.title}` })
        await this.userProductControl.CrearProductosUsuario(this.forma.value)
        this.loading.dismiss();
        this.presentToast('Saved with success', 'success');
        this.dismissModal();
      }
    } catch (error) {
      this.presentToast('Error saving', 'danger');
      console.log(error)
    }
  }

  DesplegarFormulario() {
    this.PermitirNuevoProducto = true;
  }

  async GuardarImagen(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.NuevoArchivo = event.target.files[0];
      const reader = new FileReader();
      reader.onload = ((image) =>{
        this.NuevaImagen = image.target.result as string;
        this.forma.patchValue({src:image.target.result})
      });
      reader.readAsDataURL(event.target.files[0])
    }
  }
  async getimgprod(objeto:any) {
    try {
      await this.util.loading();
      this.nombre_img = objeto.target.files[0];
      this.img_prod = objeto.target.files[0];
      this.img_count.push(this.img_prod);

      this.objectURL = URL.createObjectURL(objeto.target.files[0]);
      this.imageChangedEvent = objeto;
      this.croppedImage = objeto;
      this.croppedImage_1 = objeto;
      console.log(this.objectURL);
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarGaleria(xvalor:any){
    try {
      this.util.loading();
      console.log(xvalor);
      this.rows = [];
      var data = {
        cod_deposito:localStorage.getItem('cod_deposito'),
        cod_usuario:localStorage.getItem('cod_usuario'),
        cod_producto:xvalor.cod_producto
      };
     const response =  await this.skservice.getGaleriahttp(data).toPromise();
    console.log(response);
     this.rows = [...response.img];
    } catch (error) {
     
      console.log(error);
    }
  }

  verificar_img(){
    if (this.img_prod === '') {
      this.imagenProd_update();
    }else{
      this.imagenProd();
    }
  }

  async imagenProd() {
    try {
      await this.util.loading();
      if (isNaN(parseFloat(this.forma.value.precio_producto))) {
        await this.util.ToastWarning('Wrong price');
        return;
      }
      if (this.img_prod !== '' && this.nombre_img.name.length <= parseInt(environment.nombre_img) &&  this.nombre_img.name.length >= 1 ) {
        console.log(this.nombre_img );
      const response =  await this.store.CargarImagenPerfil(this.nombre_img);
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_img: response,
        cod_deposito: localStorage.getItem('cod_deposito'),
        des_producto: this.forma.value.des_producto,
        cod_producto: this.forma.value.cod_producto,
        cant_producto: this.forma.value.cant_producto,
        tipo_producto: this.forma.value.tipo_producto,
        precio_producto: this.forma.value.precio_producto,
        cat_producto: this.forma.value.cat_producto,
        operacion: this.forma.value.operacion,
      }
      
    this.modalController.dismiss(data);
    this.forma.reset();
      
      }else{
        
        if (this.img_prod === '') {
          await this.util.ToastWarning('You must upload an image to continue');
        }
        if (this.img_prod.name.length > parseInt(environment.nombre_img)) {
          await this.util.ToastWarning('Image name must be less than '+parseInt(environment.nombre_img)+' characters');
        }
        if (this.img_prod.name.length < 1) {
          
          await this.util.ToastWarning('You can not upload unnamed images');
        }
        
      }
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async imagenProd_update() {
    try {
      await this.util.loading();
      if (isNaN(parseFloat(this.forma.value.precio_producto))) {
        await this.util.ToastWarning('Wrong price');
        return;
      }
      if (this.img_prod_all !== '' ) {
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_img: this.img_prod_all,
        cod_deposito: localStorage.getItem('cod_deposito'),
        des_producto: this.forma.value.des_producto,
        cod_producto: this.forma.value.cod_producto,
        cant_producto: this.forma.value.cant_producto,
        tipo_producto: this.forma.value.tipo_producto,
        precio_producto: this.forma.value.precio_producto,
        cat_producto: this.forma.value.cat_producto,
        operacion: this.forma.value.operacion,
      }
      
    this.modalController.dismiss(data);
    this.forma.reset();
      
      }else{
        
        if (this.img_prod === '') {
          await this.util.ToastWarning('You must upload an image to continue');
        }
        if (this.img_prod.name.length > parseInt(environment.nombre_img)) {
          await this.util.ToastWarning('Image name must be less than '+parseInt(environment.nombre_img)+' characters');
        }
        if (this.img_prod.name.length < 1) {
          
          await this.util.ToastWarning('You can not upload unnamed images');
        }
        
      }
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async imagenProdGaleria() {
    try {
      await this.util.loading();
      if (this.rows.length === 5) {
        await this.util.ToastWarning('You can only upload 5 images for now');
        return;
      }
      
      if (this.img_prod !== '' && this.nombre_img.name.length <= parseInt(environment.nombre_img) &&  this.nombre_img.name.length >= 1 ) {
        console.log(this.img_prod );
        const response =  await this.store.CargarImgCrop(this.img_prod, this.nombre_img, this.param_img);
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_img: response,
        cod_deposito: localStorage.getItem('cod_deposito'),
        des_producto: this.forma2.value.des_producto,
        cod_producto: this.forma2.value.cod_producto,
        cant_producto: this.forma2.value.cant_producto,
        tipo_producto: this.forma2.value.tipo_producto,
        precio_producto: this.forma2.value.precio_producto,
        cat_producto: this.forma2.value.cat_producto,
        operacion: this.forma2.value.operacion,
      }
      console.log(data);
      const response_ = await this.skservice.getImgProductoGaleriahttp(data).toPromise();
      await this.util.ToastSuccess(response_.message);
      this.listarGaleria(data);
      
      }else{
        
        if (this.img_prod === '') {
          await this.util.ToastWarning('You must upload an image to continue');
        }
        if (this.img_prod.name.length > parseInt(environment.nombre_img)) {
          await this.util.ToastWarning('Image name must be less than '+parseInt(environment.nombre_img)+' characters');
        }
        if (this.img_prod.name.length < 1) {
          
          await this.util.ToastWarning('You can not upload unnamed images');
        }
        
      }
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async eliminarProdGaleria(xvalor:any) {
    try {
      await this.util.loading();
    
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_producto: xvalor.cod_producto,
        cod_img: xvalor.cod_img,
      }
      
      const response = await this.skservice.getQuitarGaleriahttp(data).toPromise();
      console.log(response);
      await this.util.ToastSuccess(response.message);
      
      this.listarGaleria(this.data);
    } catch (error) {
      console.log(error);
    }
  }
 

  async presentToast(msg: string, color: string) {
    const toast = await this.toastController.create({
      message: msg,
      cssClass: 'normal',
      duration: 2000,
      color,
    });
    toast.present();
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'normal',
      message: 'Save...',
    });
    await this.loading.present();
  }

  async dismissModal() {
    this.modalController.dismiss();
  }

  hideDiv(elem) {
    this.validacion = elem.target.value;
    console.log(this.validacion, 'revisar');
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}

imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImage_1 = event.base64;
   
    console.log(event);
    this.param_img = event.cropperPosition.x1+event.imagePosition.x1
    this.img_prod = base64ToFile(event.base64);
   
}

imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
}

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
}

loadImageFailed() {
    console.log('Load failed');
}

rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
}

flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
}

resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}
}
