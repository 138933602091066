import { UiKitController } from '../../../../services/uikitcontroller.service';
import { FirechatService } from 'src/app/services/firechat.service';
import { OfertaService } from '../../../../services/oferta.service';
import { ModalController } from '@ionic/angular';
import { OfertarModalComponent } from '../../../../componentes/modalslayout/ofertar-modal/ofertar-modal.component';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { ProductosService } from 'src/app/services/productos.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Productos, Usuario } from 'src/models';
import { skServices } from 'src/app/services/sk.services.service';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-form-producto-usuario',
  templateUrl: './form-producto-usuario.component.html',
  styleUrls: ['./form-producto-usuario.component.scss'],
})
export class FormProductoUsuarioComponent implements OnInit {
  @Input() data: any;
  id: any;
  tipo_usuario:any = localStorage.getItem('usuario_tipo');
  forma:FormGroup;
  rows: any;
  rows_prod: any;
  rows_prod_0: any;
  dlr: any;
  dlr_data_id: any;
  dlr_data: any = {
    nom_usuario:'',
    categoria:''
  };
  propietarioDelProducto = false
  cliente: Usuario = {
    tipo:'',
    tipoUsuario:'',
    userId: '',
    email: '',
    displayName: '',
    nombre: '',
    apellido: '',
    genero: '',
    fecha_nacimiento: '',
    edad: '',
    descripcion: '',
    photoURL: '',
    telefono: '',
    direccion: {
      pais: '',
      dir_str: '',
      location: {
        lat: '',
        long: '',
      },
    },
    productos:[]

  };
  producto: Productos = {
    src: '',
    alt: '',
    Id: '',
    user_id: '',
    user_name: '',
    title: '',
    cant_producto: 0,
    price: '',
    descripcion: '',
  }
  productoRight: Productos = {
    src: '',
    alt: '',
    Id: '',
    user_id: '',
    user_name: '',
    title: '',
    cant_producto: 0,
    price: '',
    descripcion: '',
  };
  producto_usuario:any = [];

  ProductoListadoTop: any;
  Carrito2 = this.carritoService.miTarjeta$;
  objeto:any;

  constructor(
    private carritoService: CarritoService,
    private route: ActivatedRoute,
    private firebaseauthService: AuthGuardServicesService,
    private productosControl: ProductosService,
    private usuariosControl: UsuariosService,
		private modalController: ModalController,
		private ofertaSV: OfertaService,
		private ChatSv: FirechatService,
		private UiKit: UiKitController,
    private skservice: skServices,
    private util: UiKitController,
    private fb:FormBuilder,
    private router: Router,
    private afs: AngularFirestore,
		public afAuth: AngularFireAuth,
  ) {
    this.forma = this.fb.group({
			cant_producto: ['1']
		  });
  }

 async ngOnInit() {
    console.log(this.data);
    if (this.tipo_usuario === 'MMBR' || this.tipo_usuario === 'VSTNT') {
    await  this.getdepDlr();
     await this.getProductosTop();
    }
  }
  back() {
    history.back();
  }

 async getdepDlr(){
    try {
      const response =  await this.skservice.getDepositoProdhttp(this.data).toPromise();
      console.log(response);
      const response_ =  await this.skservice.getImgDLRttp(response.dlr[0]).toPromise();
      console.log(response_);
      this.dlr_data = response.dlr[0];
      this.dlr_data_id = response.dlr[0];
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }

 async AgregarAlCarrito() {
    /* this.carritoService.AgregarProducto(this.producto); */
    try {
      await this.util.loading();
      if (!isNaN(this.forma.value.cant_producto)) {
        if (parseInt(this.forma.value.cant_producto) > 0) {
          var xvalor = {
            cant_producto: this.forma.value.cant_producto,
            cod_producto: this.data.cod_producto,
            des_producto: this.data.des_producto,
            precio_producto: this.data.um_producto_operador,
            um_producto: this.data.um_producto,
            cod_deposito: this.data.cod_deposito,
            cod_img: this.data.cod_img,
            cod_cliente: localStorage.getItem('cod_usuario'),
            cod_vendedor: this.data.cod_prdr,
            cat_producto: this.data.cat_producto,
            tipo: this.data.tipo_producto_cat,
            estatus: this.data.estatus,
            total_precio_pedido: parseFloat(this.forma.value.cant_producto) * parseFloat(this.data.um_producto_operador),
            total_precio_producto: parseFloat(this.forma.value.cant_producto) * parseFloat(this.data.um_producto_operador),
          }
          
          const response =  await this.skservice.getPedidoCarritohttp(xvalor).toPromise();
          console.log(response);
          this.afs
                  .collection('pedidos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('pedido')
                  .doc(response.pedido[0].cod_pedido_carrito)
                  .set({
                    estatus:'A',
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        for (const i in response.pedido) {
          this.afs
                  .collection('pedidos_usuarios')
                  .doc(localStorage.getItem('cod_usuario'))
                  .collection('pedido')
                  .doc(response.pedido[i].cod_pedido_carrito)
                  .collection('productos')
                  .doc(response.pedido[i].cod_producto)
                  .set({
                    des_producto: response.pedido[i].des_producto,
                    fecha: firebase.firestore.FieldValue.serverTimestamp(),
                    
                  });
        }
          
          this.util.ToastSuccess(response.message);
          this.dismissModal();
        }else{
          
        this.util.ToastWarning('Quantity must be greater than 0');
        }
      }else{
        
        this.util.ToastWarning('Only numbers');
      }
    } catch (error) {
      
      console.log(error);
      this.util.ToastWarning(error.error.message);
    }
  }

	async openOfertar(){
		try {
      await this.util.loading();
      if (!isNaN(this.forma.value.cant_producto)) {
        if (parseInt(this.forma.value.cant_producto) > 0) {
          await this.util.loading();
			const modal = await this.modalController.create({
				component: FormMsgComponent,
				cssClass: 'form-precio',
        backdropDismiss: false,
				componentProps: {
					data_prod:this.data
				},
			});
      
			await modal.present();
			const { data } = await modal.onDidDismiss()
			console.log(data)
			if(data !== '' && data !== null && data !== undefined){
        console.log(data);
				await this.util.loading();
				const oferta = await this.ofertaSV.crearOferta(this.producto_usuario, data.monto);
        var xobjeto = {
          cod_oferta:oferta.ofertaId,
          cod_usuario:localStorage.getItem('cod_usuario'),
          cod_prdr:this.data.cod_usuario,
          cod_producto:this.data.cod_producto,
          des_producto:this.data.des_producto,
          um_producto_oferta:oferta.precio,
          cod_deposito:this.data.cod_deposito,
          cod_img:this.data.cod_img,
          cant_producto:this.data.cant_producto,
          um_producto_operador:this.data.um_producto_operador,
          cat_producto:this.data.cat_producto,
          tipo_producto_cat:this.data.tipo_producto_cat,

        };
        const response = await this.skservice.getOfertaProductohttp(xobjeto).toPromise();
        console.log(oferta);
        this.cliente.userId = this.data.id_cloud;
        this.cliente.displayName = this.data.nom_usuario;
        this.cliente.photoURL = this.data.cod_img;
        this.cliente.apellido = this.data.ape_usuario;
        this.cliente.descripcion = this.data.descrip_usuario;
        this.cliente.tipo = this.data.tipo;
        this.cliente.tipoUsuario = this.data.tipo;
				await this.ChatSv.sendMessageOferta(this.cliente, data.mensaje,'offline', oferta)
				
        this.modalController.dismiss();
        this.util.ToastSuccess(response.message);
        this.router.navigate(['/chat']);
			}
        }else{
          
        this.util.ToastWarning('Quantity must be greater than 0');
        }
      }else{
        
        this.util.ToastWarning('Only numbers');
      }
      
		} catch (error) {
      
			this.UiKit.ToastError(error.error.message);
			console.log(error)
		}
	}

  async getProductosTop() {
    try {
      const response =  await this.skservice.getDepositoProdhttp(this.data).toPromise();
     for (const i in response.deposito) {
      for (const k in response.img) {
        if (response.deposito[i].cod_producto.toUpperCase() === response.img[k].cod_producto.toUpperCase()) {
          response.deposito[i].cod_img = response.img[k].cod_img;
          this.producto_usuario.push(response.deposito[i]);
        }
      }
     }
     console.log(this.producto_usuario);
     this.rows = [...this.producto_usuario];
     const array = [];

     for (let i = 0; i < 6; i++) {
       const item = this.rows[Math.floor(Math.random() * this.rows.length)];
       array.push(item);
     }
     this.rows_prod = array;
     this.rows_prod_0 = array[0];
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async getShowDlr() {
    try {
      await this.util.loading();
      console.log(this.dlr_data_id);
      this.modalController.dismiss(this.dlr_data_id);
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async dismissModal() {
    this.modalController.dismiss();
    
  }
  async incremento() {
    let cant_prod = document.querySelector("#cant_prod") as HTMLIonInputElement;
    cant_prod.value = parseInt(this.forma.value.cant_producto) + 1;
  }
  async decremento() {
    if (parseInt(this.forma.value.cant_producto) > 0) {
      let cant_prod = document.querySelector("#cant_prod") as HTMLIonInputElement;
    cant_prod.value = parseInt(this.forma.value.cant_producto) - 1;
    }
  }
}
