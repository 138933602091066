import { Injectable } from '@angular/core';
import { statusConection, Usuario } from 'src/models';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StatusController } from './statuscontroller.service';


@Injectable({
  providedIn: 'root',
})
export class NotificacionesSevices {
  constructor(
    private afs: AngularFirestore,
    private status: StatusController
  ) {}

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  getNotificaciones(id: string) {
    return this.afs.collection('Notificaciones').doc(id).valueChanges();
  }
  getNotificacionesList(id: string) {
    return this.afs.collection('Notificaciones').doc(id).collection('listNotificaciones').valueChanges();
  }
  getNotideseos(id: string) {
    return this.afs.collection('deseos_usuarios').doc(id).collection('deseo').valueChanges();
  }
  getchatfin(id: string) {
    return this.afs
			.collection('Clientes')
			.doc(id)
			.collection('userChats', (ref) => ref.orderBy('fecha', 'desc'))
			.valueChanges();
  }
  showNotificaciones(titulo: string,texto: string, user_data1: Usuario, user_data2: Usuario) {
  }
  crearNotificacion(texto: string, remitente: Usuario, detinatario: Usuario) {
    const notificationId = this.afs.createId();
    const data = {
      notificationId,
      texto,
      titulo: `s${remitente.displayName}`,
      timestamp: this.timestamp ,
      infoUser:{
        userId:remitente.userId,
        displayName: remitente.displayName,
        photoURL: remitente.photoURL
      },
      visto:false
    };
    this.afs
      .collection('Notificaciones')
      .doc(detinatario.userId)
      .update({
        notificacionesCantSinVer: firebase.firestore.FieldValue.increment(1)
      })
    this.afs
      .collection('Notificaciones')
      .doc(detinatario.userId)
      .collection('listNotificaciones')
      .doc(notificationId)
      .set(data)
    return

  }

  crearNotificacionMensaje(
    texto: string,
    user_data1: Usuario,
    user_data2: Usuario,
    status: statusConection,

  ) {
    if(status.status === 'online'){
      return
    }
    else{
      this.afs
      .collection('Notificaciones')
      .doc(user_data2.userId)
      .update({
        chatsSinVer: firebase.firestore.FieldValue.increment(1)
      })
    return
    }
  }

  limpiarNotificacionesChat(
    userId: string,
  ){
    this.afs
      .collection('Notificaciones')
      .doc(userId)
      .update({
        chatsSinVer: 0
      })

  }
  limpiarNotificaciones(
    userId: string,
  ){
    this.afs
      .collection('Notificaciones')
      .doc(userId)
      .update({
        notificacionesCantSinVer: 0
      })

  }
}
