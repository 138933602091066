import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, MenuController, ModalController } from '@ionic/angular';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { PagosService } from 'src/app/services/pagos.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { environment } from 'src/environments/environment';
import iziToast from 'izitoast';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FormOperacionComponent } from '../form-operacion/form-operacion.component';

@Component({
  selector: 'app-form-producto-precio',
  templateUrl: './form-producto-precio.component.html',
  styleUrls: ['./form-producto-precio.component.scss'],
})
export class FormProductoPrecioComponent implements OnInit {

  @Input() data: any;
  data_usuario:any;
  data_total:any;
  data_productos:any = [];
 
  constructor(
    private modalController: ModalController,
    private loadindControl: LoadingController,
    private fb: FormBuilder,
    private util: UiKitController,
    private userControl: AuthGuardServicesService,
    private store: StoreService,
    private skservice: skServices,
    private menucontroler: MenuController,
		private firestoreService: StoreService,
		private carritoService: CarritoService,
		private firebaseauthService: AuthGuardServicesService,
		private loadingController: LoadingController,
		private router: Router,
		private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) { 
    this.data_usuario = localStorage.getItem('cod_usuario');
  }
  async ngOnInit() {
  await  this.listarProductoOperador();
  this.data_total = this.data.total_precio_pedido;
  console.log(this.data);
  }
 async listarProductoOperador(){
    try {
     await this.util.loading();
      var xvalor = {
        cod_deposito:this.data.cod_deposito
      }
      const response = await this.skservice.getProductoOperadorhttp(xvalor).toPromise();
      this.data_productos = [... response.producto];
      
      console.log(this.data_productos);
    } catch (error) {
      
      console.log(error);
      this.data_productos = [];
      await this.util.ToastWarning(error.error.message);
    }
  }
  async modalProductoPrecio(xvalor:any){
		try {
      var xval = {
        cod_producto:xvalor.cod_producto,
        cod_deposito:xvalor.cod_deposito
      }
      const objeto = await this.skservice.getProductoActivoHomehttp(xval).toPromise();
      console.log(objeto);
      if (parseInt(objeto.resul) <= 0) {
        await this.util.ToastSuccess('This product cannot be updated because it is in the purchase process');
        await  this.listarProductoOperador();
      }else{
        await this.util.loading();
        const modal = await this.modalController.create({
        component: FormOperacionComponent,
        cssClass: 'form_data_precio',
        componentProps: { data:xvalor},
        backdropDismiss: false
        });
        
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data !== '' && data !== null && data !== undefined) {
          console.log(data);
          var xobjeto = {
            cod_producto:xvalor.cod_producto,
            des_producto:xvalor.des_producto,
            cod_deposito: xvalor.cod_deposito,
            cat_producto: data.cat_producto,
            tipo: data.tipo,
            cod_prdr:localStorage.getItem('cod_usuario'),
            um_producto_operador: data.monto,
            des_comentario:data.mensaje,
            estatus:xvalor.estatus
          };
          console.log(xobjeto);
          await this.util.loading();
          const response = await this.skservice.getProductoPrecioOperadorhttp(xobjeto).toPromise();
          console.log(response);
          await this.util.ToastSuccess(response.message);
          
          await this.listarProductoOperador();
        }else{
          await this.listarProductoOperador();
        }
      }
		} catch (error) {
		  console.log(error);
			await this.util.ToastWarning(error.error.message);
		}
		
	  }
 
  async dismissModal() {
    this.modalController.dismiss();
  }

}
