import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, MenuController, ModalController } from '@ionic/angular';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { PagosService } from 'src/app/services/pagos.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { environment } from 'src/environments/environment';
import iziToast from 'izitoast';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-form-pedidos',
  templateUrl: './form-pedidos.component.html',
  styleUrls: ['./form-pedidos.component.scss'],
})
export class FormPedidosComponent implements OnInit {
  @Input() data: any;
  data_usuario:any;
  data_total:any;
  data_productos:any = [];
  card:any;
  stripe: Stripe;
  stripe_data: PagosService;
  cod_tdc: any;
	cod_fecha: any;
	cod_cvc: any;
  forma: any = this.fb.group({
		numero: [''],
		direccion: [''],
		cardCvc: [false, [Validators.required, Validators.requiredTrue]],
		cardExp: [false, [Validators.required, Validators.requiredTrue]],
		cardNumber: [false, [Validators.required, Validators.requiredTrue]],
	});
  constructor(
    private modalController: ModalController,
    private loadindControl: LoadingController,
    private fb: FormBuilder,
    private util: UiKitController,
    private userControl: AuthGuardServicesService,
    private store: StoreService,
    private skservice: skServices,
    private menucontroler: MenuController,
		private firestoreService: StoreService,
		private carritoService: CarritoService,
		private firebaseauthService: AuthGuardServicesService,
		private loadingController: LoadingController,
		private router: Router,
		private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) { 
    this.data_usuario = localStorage.getItem('cod_usuario');
  }
  async ngOnInit() {
  await  this.listarPedidos();
  await  this.tdcActiva();
  await this.cargarElementStripe();
  this.data_total = this.data.total_precio_pedido;
  console.log(this.data);
  }
 async listarPedidos(){
    try {
     await this.util.loading();
      var xvalor = {
        cod_usuario:this.data_usuario
      }
      const response = await this.skservice.getPedidoCarritoActivohttp(xvalor).toPromise();
      this.data_productos = [... response.pedido];
      
      console.log(this.data_productos);
    } catch (error) {
      console.log(error);
      this.data_productos = [];
      await this.util.ToastWarning(error.error.message);
    }
  }
  async tdcActiva(){
    try {
     await this.util.loading();
      var xvalor = {
        cod_usuario:this.data_usuario
      }
      const response = await this.skservice.getTdcUsuarioActivahttp(xvalor).toPromise();
      if (parseInt(response.tdc[0].resul) > 0 ) {
        this.card = false;
      }else{
        this.card = true;
      }
      
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async cargarElementStripe(){
    try {
      const card1 = document.querySelector('#card-2') as HTMLDivElement;
      console.log(card1);
		if (card1) {
			this.stripe = await loadStripe(environment.stripeKey);
			const elements = this.stripe.elements();

			const style = {
				base: {
					iconColor: '#0ccde4',
					fontSize: '20px',
					color:'#212529',
				},
				invalid: {
					color: '#dc3545',
				},
			};

			const cardNumber = elements.create('cardNumber', {
				style,
				placeholder: 'Card',
				classes: {
					base: 'input-excep',
				},
			});
			const cardExpiry = elements.create('cardExpiry', {
				style,
				placeholder: 'Exp',
				classes: {
					base: 'input-excep',
				},
			});
			const cardCvc = elements.create('cardCvc', {
				style,
				placeholder: 'CVC',
				classes: {
					base: 'input-excep',
				},
			});

			cardNumber.mount('#card-2');
			cardExpiry.mount('#exp-1');
			cardCvc.mount('#cvc-1');

			this.cod_tdc = cardNumber;
			this.cod_fecha = cardExpiry;
			this.cod_cvc = cardCvc;

			this.cod_tdc.on('change', this.onChangeCard.bind(this));
			this.cod_fecha.addEventListener('change', this.onChangeCvc.bind(this));
			this.cod_cvc.on('change', this.onChangeExp.bind(this));
		}
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async enviarStripe(xvalor:any) {
		try {
      await this.util.loading();
  if (this.data_productos.length > 0) {
    const { token } = await this.stripe.createToken(this.cod_tdc);
    if (token) {
      let xvalor = {
        amount: parseFloat(this.data.total_precio_pedido),
        currency:'usd',
        token:token.id
      }
      this.skservice.getPagosPedidohttp(xvalor).subscribe(
        response=>{
          console.log(response.pago.status,'stripe_payment');
          var xpedido = {
            cod_pedido_carrito:this.data.cod_pedido_carrito,
            cod_stripe: response.pago.id
          }
          this.skservice.getFacturarPedidohttp(xpedido).subscribe(
            response =>{
              this.afs
                .collection('pedidos_usuarios')
                .doc(localStorage.getItem('cod_usuario'))
                .collection('pedido')
                .doc(this.data.cod_pedido_carrito)
                .set({
                  estatus:'I',
                  fecha: firebase.firestore.FieldValue.serverTimestamp(),
                  
                });
              this.util.closeLoading();
              this.util.ToastSuccess(response.message);
              this.dismissModal();
            },
            error =>{
              console.log(error);
              this.util.ToastWarning(error.error.message);
            }
          );
        },
        error=>{
          console.log(error);
          this.util.ToastError(error.error.message);
        }
      ); 
    }else{
      await this.util.ToastError('There was a problem making the payment');
    }
  }else{
    
    await this.util.ToastWarning('This order have not products');
  }
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
	}
  async quitarElementosPedidoh(xval:any){
    try {
      xval.total_precio_pedido = this.data.total_precio_pedido;
      console.log(xval);
      await this.util.loading();
      const response = await this.skservice.getQuitarElementosPedidohttp(xval).toPromise();
      console.log(response);
      this.data.total_precio_pedido = response.pedido[0].total_precio_pedido;
      this.data_total = response.pedido[0].total_precio_pedido;
        await this.util.ToastSuccess(response.message);
        
        await  this.listarPedidos();
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
    
  }

  onChangeCard({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardNumber: !error });
	}
	onChangeCvc({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardCvc: !error });
	}
	onChangeExp({ error }: any) {
		console.log(error, this.forma);
		this.forma.patchValue({ cardExp: !error });
	}

}
