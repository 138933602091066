import { Component, OnInit,ViewChild, OnDestroy} from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute,Params, Router } from '@angular/router';
import { CarritoService } from 'src/app/services/carrito.service';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { StoreService } from 'src/app/services/store.service';
import { Productos, Usuario } from 'src/models';
import { UserService } from 'src/app/services/user.service';
import { ModalFormUserComponent } from 'src/app/componentes/modal-form-user/modal-form-user.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ProductosService } from 'src/app/services/productos.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { ModalProductosComponent } from 'src/app/componentes/modal-productos/modal-productos.component';
import { skServices } from 'src/app/services/sk.services.service';
import { json } from '@rxweb/reactive-form-validators';
import { FormPedidosComponent } from 'src/app/componentes/shared/modal/form-pedidos/form-pedidos.component';
import { Console } from 'console';
import { FormBilleteraComponent } from 'src/app/componentes/shared/modal/form-billetera/form-billetera.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { RouterService } from 'src/app/services/router.service';
import { FormGaleriaComponent } from 'src/app/componentes/shared/modal/form-galeria/form-galeria.component';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.page.html',
  styleUrls: ['./perfil.page.scss'],
})
export class PerfilPage implements OnInit, OnDestroy{
  @ViewChild(DatatableComponent) table: DatatableComponent;
  Uid = '';
  objectURL:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';
  croppedImage:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';
  croppedImage_1:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';

  imageChangedEvent: any = '';
  
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  um_total:any = 0.00;
  um_bloqueado:any = 0.00;
  um_disponible:any = 0.00;
  um_espera:any = 0.00;
  forma:FormGroup;
  listarPedido: any;
  carritoSuscriber: Subscription;
  Carrito2 = this.carritoService.miTarjeta$;
  seleccion: any;
  activate = false;
  cliente:any;
  data:any;
  bandera:any = false;
  data_filtro: any = [];
  img: any;
  productos: any[] = [];
  favoritos: any[] = [];
  rows: any[] = [];
  ordenes: any[] = [];
  delivery: any[] = [];
  tipo_usuario = localStorage.getItem('usuario_tipo');
  CerrarSubscripcion: Subscription;

  currentPage = 1;
  pageOfItems: Array<any>;
  categoria:any;
  data_usuario:any;
  edad_data:any;

  forma2: FormGroup;
  countries: any = [];
  img_user:any = localStorage.getItem('img');
  img_count:any = [];
  img_prod:any = '';
  img_prod_all:any = '';
  usuario:any = localStorage.getItem('usuario_tipo'); 
  param_img: number;
  nombre_img: any;

  constructor(
    public menucontroler: MenuController,
    public firebaseauthService: AuthGuardServicesService,
    public firestorangeService: StoreService,
    public modalController: ModalController,
    public router: Router,
    private util: UiKitController,
    public carritoService: CarritoService,
    public userControl: UserService,
    public productosControl: ProductosService,
    private skservice: skServices,
    private route: ActivatedRoute,
    private fb:FormBuilder,
    private fb2:FormBuilder,
    private store: StoreService,
    private router_app: RouterService,
  ) {
    if (this.usuario.toLowerCase() === 'dlr') {
      this.forma2 = this.fb2.group({
        des_usuario: ['',[Validators.required]],
        nom_usuario: ['',[Validators.required]],
        ape_usuario: ['',[Validators.required]],
        sex_usuario: ['',[Validators.required]],
        fecha_usuario: ['',[Validators.required]],
        edad_usuario: [''],
        bank_data: ['',[Validators.required]],
        dni_usuario: ['',[Validators.required]],
  
        dir_usuario: ['',[Validators.required]],
        body_data: ['',[Validators.required]],
        altura_usuario: ['',[Validators.required]],
        color_usuario: ['',[Validators.required]],
        ojos_usuario: ['',[Validators.required]],
        pelo_usuario: ['',[Validators.required]],
        fumar_usuario: ['',[Validators.required]],
        comida_usuario: ['',[Validators.required]],
        nino_usuario: ['',[Validators.required]],
        descrip_usuario: ['',[Validators.required]],
        cod_img: '',
        pais_usuario: ['',[Validators.required]],
      });
    } 
    if (this.usuario.toLowerCase() === 'mmbr') {
      this.forma2 = this.fb2.group({
        des_usuario: ['',[Validators.required]],
        nom_usuario: ['',[Validators.required]],
        ape_usuario: ['',[Validators.required]],
        sex_usuario: ['',[Validators.required]],
        fecha_usuario: ['',[Validators.required]],
        edad_usuario: [''],
        bank_data: [''],
        dni_usuario: ['',[Validators.required]],
  
        dir_usuario: ['',[Validators.required]],
        body_data: ['',[Validators.required]],
        altura_usuario: ['',[Validators.required]],
        color_usuario: ['',[Validators.required]],
        ojos_usuario: ['',[Validators.required]],
        pelo_usuario: ['',[Validators.required]],
        fumar_usuario: ['',[Validators.required]],
        comida_usuario: ['',[Validators.required]],
        nino_usuario: ['',[Validators.required]],
        descrip_usuario: ['',[Validators.required]],
        cod_img: '',
        pais_usuario: ['',[Validators.required]],
      });
    } 
    if (this.usuario.toLowerCase() === 'prdr') {
      this.forma2 = this.fb2.group({
        des_usuario: ['',[Validators.required]],
        nom_usuario: ['',[Validators.required]],
        ape_usuario: ['',[Validators.required]],
        sex_usuario: [''],
        fecha_usuario: [''],
        edad_usuario: [''],
        bank_data: [''],
        dni_usuario: [''],
  
        dir_usuario: [''],
        body_data: [''],
        altura_usuario: [''],
        color_usuario: [''],
        ojos_usuario: [''],
        pelo_usuario: [''],
        fumar_usuario: [''],
        comida_usuario: [''],
        nino_usuario: [''],
        descrip_usuario: [''],
        cod_img: '',
        pais_usuario: [''],
      });
    } 
    fetch('https://restcountries.com/v3.1/all')
      .then((res) => res.json())
      .then((response) => {
        this.countries = response.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
      })
      .catch((error) => {
        console.log(error);
      });
    this.forma = this.fb.group({
      um_disponible: ['', [Validators.required]]
    });
    
    this.userControl.getProductosUsuarioFirebase(localStorage.getItem('userId'))
    this.userControl.getProductosUsuario().subscribe(res => {
      this.productos = res;
      
      console.log(this.productos)
    });
  }
  isDiffToAnonimo(){
    const tipo = localStorage.getItem('tipo')
    return tipo !== 'VSTNT';
  }
  async ngOnInit() {
   
    this.bandera = false;
    this.cliente = localStorage.getItem('usuario_tipo');
    this.data = {
      des_usuario:localStorage.getItem('des_usuario'),
      correo_usuario:localStorage.getItem('user'),
      categoria:localStorage.getItem('usuario_categoria'),
      tipo:localStorage.getItem('tipo'),
      cod_img:localStorage.getItem('cod_img')

    } ;
    this.croppedImage_1 = this.data.cod_img;
    console.log(this.firebaseauthService.Usuario)
    this.firebaseauthService.UsuarioSubsCribe().subscribe(res =>{
    })
    this.CargarPedido();
    
  }
 async ionViewDidEnter() {
  this.bandera = false
   await this.getUserData();
    console.log(this.seleccion);
    if (this.seleccion === 'ordenes') {
     await this.listarOrdenes(null);
    }
    if (this.seleccion === 'entrega') {
      if (localStorage.getItem('usuario_tipo').toLowerCase() === 'prdr') {
       await this.listarDeliveryOperador('1');
      }
      if (localStorage.getItem('usuario_tipo').toLowerCase() === 'dlr') {
       await this.listarDelivery('1');
      }
      if (localStorage.getItem('usuario_tipo').toLowerCase() === 'mmbr') {
      await  this.listarDeliveryCliente('1');
      }
    }
    if (this.seleccion === 'favoritos') {
     await this.listarFavoritos(null);
    }
    if (this.seleccion === 'productos') {
    await  this.listarProductos(null);
    }
    if (this.seleccion === 'wallet') {
     await this.getBilleteraVendedor();
    }
    await this.getDataUsuario();
   }
   getUserData(){
    try {
        this.route.params.subscribe(async (params: Params) => {
          console.log(params);
          this.seleccion = params.data;
        });
        
    } catch (error) {
      console.log(error);
    }
  }
  async fechaCumpleanos(event: any) {
    try {
      let fecha_data = document.querySelector("#inputfecha") as HTMLSelectElement;
      let edad_dat= document.querySelector("#input5edad") as HTMLSelectElement;
      console.log(fecha_data.value);
      await this.util.loading();
    const fecha = moment(fecha_data.value, 'YYYY-MM-DD');
    const hoy = moment();
    const edad = hoy.diff(fecha,"years");
    console.log(edad);
    edad_dat.value = edad.toString();
    console.log(edad_dat.value);
    this.edad_data = edad.toString();
    this.forma.value.edad_usuario = this.edad_data;
    } catch (error) {
      console.log(error);
    }
  }
  async fechaCumpleanos2(event: any) {
    try {

      let fecha_data = document.querySelector("#inputfecha55") as HTMLSelectElement;
      let edad_dat= document.querySelector("#input55edad") as HTMLSelectElement;
      console.log(fecha_data.value);
      await this.util.loading();
    const fecha = moment(fecha_data.value, 'YYYY-MM-DD');
    const hoy = moment();
    const edad = hoy.diff(fecha,"years");
    console.log(edad);
    edad_dat.value = edad.toString();
    console.log(edad_dat.value);
    this.edad_data = edad.toString();
    this.forma2.value.edad_usuario = this.edad_data;
    } catch (error) {
      console.log(error);
    }
  }
   onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnDestroy(): void {
    this.userControl.Unsubscribe()
    URL.revokeObjectURL(this.objectURL);
  }

  openMenu() {
    this.menucontroler.open('principal');
  }

  /* async LoginWithGoogle() {
    this.firebaseauthService.LoginWithGoogle();
  } */

  Salir() {
    this.firebaseauthService.logout();
  }

  CargarPedido() {
    this.carritoSuscriber = this.carritoService
      .ListarCarrito()
      .subscribe((res) => {
        this.listarPedido = res;

        const cantidadCarrito = this.listarPedido.Productos.length
          ? this.listarPedido.Productos.length
          : 0;

        localStorage.setItem('carrito', JSON.stringify(cantidadCarrito));
        this.carritoService.cargarPedidoCliente(res.Productos);
      });
  }

 async show(select: any) {
    if(this.isDiffToAnonimo()){
      await this.util.loading();
      this.seleccion = select;
      this.activate = true;
      if (this.seleccion.toLowerCase() === 'favoritos') {
        this.listarFavoritos('1');
      }
      if (this.seleccion.toLowerCase() === 'productos') {
        this.listarProductos('1');
      }
      if (this.seleccion.toLowerCase() === 'ordenes') {
        this.listarOrdenes('1');
      }
      if (this.seleccion.toLowerCase() === 'entrega') {
        if (localStorage.getItem('usuario_tipo').toLowerCase() === 'prdr') {
          this.listarDeliveryOperador('1');
        }
        if (localStorage.getItem('usuario_tipo').toLowerCase() === 'dlr') {
          this.listarDelivery('1');
        }
        
      }
    }else{
      await this.util.loading();
      this.firebaseauthService.login()
    }
  }
  async listarProductos(msg:any){
    try {
      var data = {
        cod_deposito:localStorage.getItem('cod_deposito')
      };
     const response =  await this.skservice.getDepositoProductohttp(data).toPromise();
     console.log(response);
     var productos = [];
     for (const i in response.deposito) {
      for (const k in response.img) {
        if (response.deposito[i].cod_producto.toUpperCase() === response.img[k].cod_producto.toUpperCase()) {
          response.deposito[i].cod_img = response.img[k].cod_img;
          productos.push(response.deposito[i]);
        }
      }
     }
     this.rows = [...productos];
     
     if (msg !== null) {
      await this.util.ToastSuccess(response.message);
     }
    } catch (error) {
      await this.util.ToastWarning(error.error.message);
      console.log(error);
    }
  }
  async listarFavoritos(msg:any){
    try {
      var data = {
        cod_usuario:localStorage.getItem('cod_usuario')
      };
     const response =  await this.skservice.getFavoritoshttp(data).toPromise();
     console.log(response);
     var favoritos = [];
     for (const i in response.usuario) {
        if (response.usuario[i].tipo_evento === '1') {
          favoritos.push(response.usuario[i]);
        }
     }
     this.favoritos = [...favoritos];
     this.data_filtro = [...favoritos];
   
     if (msg !== null) {
      await this.util.ToastSuccess(response.message);
     }
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarOrdenes(msg:any){
    try {
      this.ordenes = [];
      var data = {
        cod_deposito:localStorage.getItem('cod_deposito')
      };
     const response =  await this.skservice.getOrdenesProductohttp(data).toPromise();
     
     this.ordenes = [...response.ordenes];
     console.log(this.ordenes);
     if (msg !== null) {
      await this.util.ToastSuccess(response.message);
     }
    } catch (error) {
      await this.util.ToastWarning(error.error.message);
      console.log(error);
    }
  }
  async listarDelivery(msg:any){
    try {
      var data = {
        cod_deposito:localStorage.getItem('cod_deposito')
      };
     const response =  await this.skservice.getDeliveryhttp(data).toPromise();
     
     this.delivery = [...response.delivery];
     console.log(this.delivery);
     if (msg !== null) {
      await this.util.ToastSuccess(response.message);
     }
    } catch (error) {
      await this.util.ToastWarning(error.error.message);
      console.log(error);
    }
  }
  async listarDeliveryCliente(msg:any){
    try {
      var data = {
        cod_usuario:localStorage.getItem('cod_usuario')
      };
     const response =  await this.skservice.getDeliveryClientehttp(data).toPromise();
     
     this.delivery = [...response.delivery];
     console.log(this.delivery);
     if (msg !== null) {
      await this.util.ToastSuccess(response.message);
     }
    } catch (error) {
      await this.util.ToastWarning(error.error.message);
      console.log(error);
    }
  }
  async listarDeliveryOperador(msg:any){
    try {
      var data = {
        cod_prdr:localStorage.getItem('cod_usuario')
      };
     const response =  await this.skservice.getDeliveryOperadorhttp(data).toPromise();
     
     this.delivery = [...response.delivery];
     console.log(this.delivery);
     if (msg !== null) {
      await this.util.ToastSuccess(response.message);
     }
    } catch (error) {
      await this.util.ToastWarning(error.error.message);
      console.log(error);
    }
  }
  async getDataUsuario(){
    try {
      var data = {
        cod_usuario:localStorage.getItem('cod_usuario')
      };
     const response =  await this.skservice.getDataUsuariohttp(data).toPromise();
     console.log(response);
     this.data_usuario = [...response.usuario];
     this.objectURL = response.usuario[0].cod_img;
     this.img_prod_all = response.usuario[0].cod_img;
     this.forma2.patchValue({
                              altura_usuario:response.usuario[0].altura_usuario,
                              ape_usuario:response.usuario[0].ape_usuario,
                              bank_data:response.usuario[0].bank_data,
                              body_data:response.usuario[0].body_data,
                              color_usuario:response.usuario[0].color_usuario,
                              comida_usuario:response.usuario[0].comida_usuario,
                              des_usuario:response.usuario[0].des_usuario,
                              descrip_usuario:response.usuario[0].descrip_usuario,
                              dir_usuario:response.usuario[0].dir_usuario,
                              dni_usuario:response.usuario[0].dni_usuario,
                              edad_usuario:response.usuario[0].edad_usuario,
                              fecha_usuario:response.usuario[0].fecha_usuario,
                              fumar_usuario:response.usuario[0].fumar_usuario,
                              nino_usuario:response.usuario[0].nino_usuario,
                              nom_usuario:response.usuario[0].nom_usuario,
                              ojos_usuario:response.usuario[0].ojos_usuario,
                              pais_usuario:response.usuario[0].pais_usuario,
                              pelo_usuario:response.usuario[0].pelo_usuario,
                              sex_usuario:response.usuario[0].sex_usuario,
                            });
     
     console.log(this.data_usuario);
     
    } catch (error) {
      console.log(error);
      
      
    }
  }

  desactivateArrow() {
    this.activate = false;
  }

  async configUser() {
    if(this.isDiffToAnonimo()){
      await this.util.loading();
      await this.firebaseauthService.configurarUsuario();
    }else{
      this.firebaseauthService.login()
    }
  }
  async configurarUsuario() {
    try {
      const modal = await this.modalController.create({
        component: ModalFormUserComponent,
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        componentProps: {
          Usuario: ''
        },
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
              if (data !== undefined && data !== null && data !== '') {
                const response = await this.skservice.getUpdateUsuariohttp(data).toPromise();
          await this.util.ToastSuccess(response.message);
          console.log(response.usuario);
          localStorage.setItem('cod_img', response.usuario[0].cod_img);
          localStorage.setItem('des_usuario', response.usuario[0].nom_usuario);
          this.data = {
            des_usuario:localStorage.getItem('des_usuario'),
            correo_usuario:localStorage.getItem('user'),
            cod_img:localStorage.getItem('cod_img')
          } ;
              }
              return modal;
    } catch (error) {
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.productos.filter((d) => d.title.toLowerCase().indexOf(val) !== -1 || !val );

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

 async modalCrearProdcutos(){
    try {
     var xvalor = {
      operacion:'0',
      tipo_producto:'',
      des_producto:'',
      um_producto:''
     };
      await this.util.loading();
      const modal = await this.modalController.create({
        component: ModalProductosComponent,
        cssClass: 'edit_producto',
        componentProps: { data:xvalor},
        backdropDismiss: false
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
   
          this.listarProductos(null);
      
      return modal;
    } catch (error) {
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
    
  }
async  modalActualizarProdcutos(xvalor:any, galeria:any){
  try {
    console.log(xvalor);
    xvalor.operacion = '1';
    xvalor.galeria = galeria;
    await this.util.loading();
    const modal = await this.modalController.create({
      component: ModalProductosComponent,
      cssClass: 'edit_producto',
      componentProps: { data:xvalor},
      backdropDismiss: false
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
    if (data !== undefined && data !== null && data !== '') {
      console.log(data);
      const response = await this.skservice.getImgProductohttp(data).toPromise();
        await this.util.ToastSuccess(response.message);
        this.listarProductos(null);
    }
    return modal;
  } catch (error) {
    console.log(error);
      await this.util.ToastWarning(error.error.message);
  }
  }
  delete(data: Productos) {
    this.userControl.EliminarProductosUsuario(data);
  }
  goTo(ruta: string, data?: any){
    if(this.isDiffToAnonimo()){
      this.router.navigate([ruta,data])
    }else{
      this.firebaseauthService.login()
    }
  }
  async endesarrollo(){
    await this.util.ToastWarning('Function in development');
  }
  async getPerfilSeller(xvalor:any) {
    try {
      await this.util.loading();
        this.router.navigate(['/proveedor',xvalor.cod_usuario]);
    } catch (error) {
      console.log(error);
      this.util.ToastError(error.error.message);
    }
    }
  async tienda_app(url: any) {
		try {
			await this.util.loading();
			this.router.navigate([url]);
			
		} catch (error) {
    }
	}
  async handleChange(event, termino:any) {
    await this.util.loading();
    if (termino === 'productos') {
      const query = event.target.value.toLowerCase();
      console.log(query);
      this.data_filtro = this.ordenes.filter(d => 
        d.um_producto.toLowerCase().indexOf(query) > -1  ||
        d.des_producto.toLowerCase().indexOf(query) > -1);
      
      console.log(this.data_filtro.length);
      if (this.data_filtro.length <= 0) {
        await this.util.ToastWarning('Without results');
      }else{
        await this.util.ToastSuccess(this.data_filtro.length+' Results Found');
      }
    }
    if (termino === 'ordenes') {
      const query = event.target.value.toLowerCase();
    console.log(query);
    this.data_filtro = this.ordenes.filter(d => 
      d.um_producto.toLowerCase().indexOf(query) > -1  ||
      d.des_producto.toLowerCase().indexOf(query) > -1);
    
    console.log(this.data_filtro.length);
    if (this.data_filtro.length <= 0) {
      await this.util.ToastWarning('Without results');
    }else{
      await this.util.ToastSuccess(this.data_filtro.length+' Results Found');
    }
    }
    if (termino === 'favoritos') {
      const query = event.target.value.toLowerCase();
    console.log(query);
    this.data_filtro = this.favoritos.filter(d => 
      d.des_usuario.toLowerCase().indexOf(query) > -1);
    
    console.log(this.data_filtro.length);
    if (this.data_filtro.length <= 0) {
      await this.util.ToastWarning('Without results');
    }else{
      await this.util.ToastSuccess(this.data_filtro.length+' Results Found');
    }
    }
    if (termino === 'entrega') {
      const query = event.target.value.toLowerCase();
    console.log(query);
    this.data_filtro = this.ordenes.filter(d => 
      d.precio_producto.toLowerCase().indexOf(query) > -1  ||
      d.des_producto.toLowerCase().indexOf(query) > -1);
    
    console.log(this.data_filtro.length);
    if (this.data_filtro.length <= 0) {
      await this.util.ToastWarning('Without results');
    }else{
      await this.util.ToastSuccess(this.data_filtro.length+' Results Found');
    }
    }
    
  }

  async getdeliveryProducto(xvalor:any){
    try {
      console.log(xvalor);
      await this.util.loading();
      const response = await this.skservice.getdeliveryProductohttp(xvalor).toPromise();
      this.listarOrdenes(null);
      this.util.ToastSuccess(response.message);
    } catch (error) {
      console.log(error);
    }
  }
  async getReceivedProducto(xvalor:any){
    try {
      console.log(xvalor);
      await this.util.loading();
      const response = await this.skservice.getReceivedProductohttp(xvalor).toPromise();
      this.listarDeliveryOperador(null);
      this.util.ToastSuccess(response.message);
    } catch (error) {
      console.log(error);
    }
  }
  async getBilleteraVendedor(){
    try {
      
     var xvalor = {
        cod_usuario:localStorage.getItem('cod_usuario')
      }
      const response = await this.skservice.getBilleteraVendedorhttp(xvalor).toPromise();
      console.log(response);
      this.um_total = parseFloat(response.valor[0].um_total) ;
      this.um_bloqueado =  parseFloat(response.valor[0].um_bloqueado);
      this.um_disponible =  parseFloat(response.valor[0].um_disponible);
      this.um_espera =  parseFloat(response.valor[0].um_espera);
      await this.util.ToastSuccess(response.message);
    } catch (error) {
      console.log(error);
      
    }
}
ponerPuntosyComas(event:any, id:any){

  let validacion = '1234567890'
  console.log(event.key)

  let inputCantidad = document.querySelector(`#${id}`) as HTMLInputElement


  if(id != 'cant_ingresada'){

    if(inputCantidad.value.length == 1){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
      
    }else if(inputCantidad.value.length == 3){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length >= 4 ){
      let noa:any = inputCantidad.value.split('')
      for(let i = 0 ; i < 2; i++){
        if(noa[0] == 0){
          noa.shift()
        }else{
          break;
        }
      }
      inputCantidad.value = noa.join('')
    }

    var num = inputCantidad.value.replace(/\D/g,'');

    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    inputCantidad.value = num;

  }else{

      if(inputCantidad.value.length == 1){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        noa.unshift('0')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
        
      }else if(inputCantidad.value.length == 4){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
      }else if(inputCantidad.value.length >= 5 ){
        let noa:any = inputCantidad.value.split('')
        for(let i = 0 ; i < 2; i++){
          if(noa[0] == 0){
            noa.shift()
          }else{
            break;
          }
        }
        inputCantidad.value = noa.join('')
      }
  
      var num = inputCantidad.value.replace(/\D/g,'');
  
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
      
      num = num.split('').reverse().join('').replace(/\D/g, "")
      .replace(/([0-9])([0-9]{3})$/, '$1.$2')
      inputCantidad.value = num;

    
  }

  if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){

    this.util.ToastWarning('Only numbers');
  
  }


}
async getimgprod(objeto:any) {
  try {
    await this.util.loading();
    this.nombre_img = objeto.target.files[0];
      this.img_count.push(this.img_prod);

    this.objectURL = URL.createObjectURL(objeto.target.files[0]);
    this.imageChangedEvent = objeto;
    this.croppedImage = objeto;
    this.croppedImage_1 = objeto;
    console.log(this.objectURL);
    
  } catch (error) {
    
    console.log(error);
    await this.util.ToastWarning(error.error.message);
  }
}
async eliminarProd(xvalor:any) {
  try {
    await this.util.loading();
  
    var data = {
      cod_producto: xvalor.cod_producto,
      cod_deposito: xvalor.cod_deposito,
    }
    
    const response = await this.skservice.getQuitarProductohttp(data).toPromise();
    console.log(response);
    await this.util.ToastSuccess(response.message);
    await  this.listarProductos(null);
   
  } catch (error) {
    console.log(error);
  }
}
verificar_img(){
  if (this.img_prod === '') {
    this.imagenProd_update();
  }else{
    this.imagenProd();
  }
}
async imagenProd() {
  try {
    let fecha_data = document.querySelector("#inputfecha") as HTMLSelectElement;
    let fecha_data55 = document.querySelector("#inputfecha55") as HTMLSelectElement;
    var fecha;
    console.log(fecha_data.value,'fecha');
    console.log(fecha_data55.value,'fecha movil');
    if (fecha_data.value) {
      fecha = moment(fecha_data.value, 'YYYY-MM-DD');
    }
    if (fecha_data55.value) {
      fecha = moment(fecha_data55.value, 'YYYY-MM-DD');
    }
    
    if (this.img_prod !== '' && this.nombre_img.name.length <= parseInt(environment.nombre_img) &&  this.nombre_img.name.length >= 1) {
      
      await this.util.loading();
    const response =  await this.store.CargarImagenPerfil(this.nombre_img);
    const fecha_usuario = moment(fecha).format('DD/MM/YYYY');
    console.log(this.forma.value.fecha_usuario);
    var data = {
      cod_usuario: localStorage.getItem('cod_usuario'),
      des_usuario: this.forma2.value.des_usuario,
      cod_img: response,
      ape_usuario: this.forma2.value.ape_usuario,
      descrip_usuario: this.forma2.value.descrip_usuario,
      edad_usuario: (this.edad_data === undefined) ? this.forma2.value.edad_usuario : this.edad_data,
      fecha_usuario: fecha_usuario,
      nom_usuario: this.forma2.value.nom_usuario,
      pais_usuario: this.forma2.value.pais_usuario,
      sex_usuario: this.forma2.value.sex_usuario,
      bank_data: this.forma2.value.bank_data,
      dni_usuario: this.forma2.value.dni_usuario,

      dir_usuario: this.forma2.value.dir_usuario,
      body_data: this.forma2.value.body_data,
      altura_usuario: this.forma2.value.altura_usuario,
      color_usuario: this.forma2.value.color_usuario,
      ojos_usuario: this.forma2.value.ojos_usuario,
      pelo_usuario: this.forma2.value.pelo_usuario,
      fumar_usuario: this.forma2.value.fumar_usuario,
      comida_usuario: this.forma2.value.comida_usuario,
      nino_usuario: this.forma2.value.nino_usuario,
    }
    console.log(data);
    const response_ = await this.skservice.getUpdateUsuariohttp(data).toPromise();
    await this.util.ToastSuccess(response_.message);
    console.log(response_.usuario);
    localStorage.setItem('cod_img', response_.usuario[0].cod_img);
    localStorage.setItem('des_usuario', response_.usuario[0].des_usuario);
   
        this.img_prod = '';

      await this.getDataUsuario();
      await this.edit_profile();
      return;
    
    }else{
      await this.util.loading();
      if (this.img_prod === '') {
        
        await this.util.ToastWarning('You must upload an image to continue');
        return;
      }
      if (this.img_prod.name.length > parseInt(environment.nombre_img)) {
        
        await this.util.ToastWarning('Image name must be less than '+environment.nombre_img+' characters');
        return;
      }
      if (this.img_prod.name.length < 1) {
        
        await this.util.ToastWarning('You can not upload unnamed images');
        return;
      }
    }
    
  } catch (error) {
    this.img_prod = '';
    console.log(error);
    await this.util.ToastWarning(error.error.message);
  }
}
async imagenProd_update() {
  try {
    let fecha_data = document.querySelector("#inputfecha") as HTMLSelectElement;
    let fecha_data55 = document.querySelector("#inputfecha55") as HTMLSelectElement;
    
    
    var fecha;
    if (fecha_data.value) {
      console.log(fecha_data.value,'fecha');
      fecha = moment(fecha_data.value, 'YYYY-MM-DD');
    }
    if (fecha_data55.value) {
      console.log(fecha_data55.value,'fecha movil');
      fecha = moment(fecha_data55.value, 'YYYY-MM-DD');
    }
    if (this.img_prod_all !== '') {
      
      await this.util.loading();
    const fecha_usuario = moment(fecha).format('DD/MM/YYYY');
    console.log(fecha_usuario);
    var data = {
      cod_usuario: localStorage.getItem('cod_usuario'),
      des_usuario: this.forma2.value.des_usuario,
      cod_img: this.img_prod_all,
      ape_usuario: this.forma2.value.ape_usuario,
      descrip_usuario: this.forma2.value.descrip_usuario,
      edad_usuario: (this.edad_data === undefined) ? this.forma2.value.edad_usuario : this.edad_data,
      fecha_usuario: fecha_usuario,
      nom_usuario: this.forma2.value.nom_usuario,
      pais_usuario: this.forma2.value.pais_usuario,
      sex_usuario: this.forma2.value.sex_usuario,
      bank_data: this.forma2.value.bank_data,
      dni_usuario: this.forma2.value.dni_usuario,

      dir_usuario: this.forma2.value.dir_usuario,
      body_data: this.forma2.value.body_data,
      altura_usuario: this.forma2.value.altura_usuario,
      color_usuario: this.forma2.value.color_usuario,
      ojos_usuario: this.forma2.value.ojos_usuario,
      pelo_usuario: this.forma2.value.pelo_usuario,
      fumar_usuario: this.forma2.value.fumar_usuario,
      comida_usuario: this.forma2.value.comida_usuario,
      nino_usuario: this.forma2.value.nino_usuario,
    }
    console.log(data);
    const response_ = await this.skservice.getUpdateUsuariohttp(data).toPromise();
    await this.util.ToastSuccess(response_.message);
    console.log(response_.usuario);
    localStorage.setItem('cod_img', response_.usuario[0].cod_img);
    localStorage.setItem('des_usuario', response_.usuario[0].des_usuario);
   
        this.img_prod = '';

      await this.getDataUsuario();
      await this.edit_profile();
      return;
    
    }else{
      await this.util.loading();
      if (this.img_prod === '') {
        
        await this.util.ToastWarning('You must upload an image to continue');
        return;
      }
      if (this.img_prod.name.length > parseInt(environment.nombre_img)) {
        
        await this.util.ToastWarning('Image name must be less than '+environment.nombre_img+' characters');
        return;
      }
      if (this.img_prod.name.length < 1) {
        
        await this.util.ToastWarning('You can not upload unnamed images');
        return;
      }
    }
    
  } catch (error) {
    this.img_prod = '';
    console.log(error);
    await this.util.ToastWarning(error.error.message);
  }
}

async imagenPerfil(objeto:any) {
  try {
    await this.util.loading();
    var img = objeto.target.files[0]
    const response =  await this.store.CargarImagenPerfil(img);
    var data = {
      cod_usuario: localStorage.getItem('cod_usuario'),
      cod_img: response,
    }
    const response_ = await this.skservice.imgPerfilhttp(data).toPromise();
    await this.util.ToastSuccess(response_.message);
    localStorage.setItem('cod_img', response_.img[0].cod_img);
    this.img = localStorage.getItem('cod_img');
  } catch (error) {
    console.log(error);
    await this.util.ToastWarning(error.error.message);
  }
}

edit_profile(){
  this.util.loading();
  if (this.bandera === false) {
    this.bandera = true;
  }else{
    this.bandera = false;
  }
}

async getQuitarFavoritos(xvalor){
  try {
    console.log(xvalor);
    await this.util.loading();
    const response = await this.skservice.getQuitarFavoritoshttp(xvalor).toPromise();
    await this.util.ToastSuccess(response.message);
    await this.listarFavoritos(null);
  } catch (error) {
    await this.util.ToastWarning(error.error.message);
  }
}

async  galeriaPerfil(){
  try {
    
    await this.util.loading();
   var xvalor = {
      cod_usuario:localStorage.getItem('cod_usuario')
    }
    const modal = await this.modalController.create({
      component: FormGaleriaComponent,
      cssClass: 'edit_producto',
      componentProps: { data:xvalor},
      backdropDismiss: false
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
    if (data !== undefined && data !== null && data !== '') {
     
    }
    return modal;
  } catch (error) {
    console.log(error);
      await this.util.ToastWarning(error.error.message);
  }
  }
  /* async getWalletDlr() {
    try {
      const modal = await this.modalController.create({
        component: FormBilleteraComponent,
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        componentProps: {
          Usuario: ''
        },
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
              if (data !== undefined && data !== null && data !== '') {
              }
              return modal;
    } catch (error) {
      console.log(error);
        await this.util.ToastWarning(error.error.message);
    }
  } */

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}

imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImage_1 = event.base64;
   
    console.log(event);
    this.param_img = event.cropperPosition.x1+event.imagePosition.x1
    this.img_prod = base64ToFile(event.base64);
   
}

imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
}

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
}

loadImageFailed() {
    console.log('Load failed');
}

rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
}

flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
}

resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}

}

