import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA, isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { APP_ROUTING } from './app-routing.module';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';

import { SETTINGS as AUTH_SETTINGS } from '@angular/fire/compat/auth';

import { HttpClientModule } from '@angular/common/http';

/* ANGULAR MATERIAL */
import { SwiperModule } from 'swiper/angular';
/* import { TooltipsModule } from 'ionic-tooltips'; */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgIdleKeepaliveModule} from '@ng-idle/keepalive'; // esto incluye el núcleo NgIdleModule pero incluye proveedores keepalive para una fácil conexión

/* COMPONENTES */
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { SharedModule } from './shared/shared.module';
import { ModalLoginComponent } from './componentes/modal-login/modal-login.component';

import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import { skServices } from './services/sk.services.service';
import { FormRegistroMiembroComponent } from './componentes/shared/modal/form-registro-miembro/form-registro-miembro.component';
import { FormLoginUsuariosComponent } from './componentes/shared/modal/form-login-usuarios/form-login-usuarios.component';
import { FormRegistroProveedorComponent } from './componentes/shared/modal/form-registro-proveedor/form-registro-proveedor.component';
import { FormLoginProveedorComponent } from './componentes/shared/modal/form-login-proveedor/form-login-proveedor.component';
import { initializeApp ,provideFirebaseApp } from '@angular/fire/app';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { FormProductoUsuarioComponent } from './componentes/shared/modal/form-producto-usuario/form-producto-usuario.component';
import { OfertarModalComponent } from './componentes/modalslayout/ofertar-modal/ofertar-modal.component';
import { FormMsgComponent } from './componentes/shared/modal/form-msg/form-msg.component';
import { FormPedidosComponent } from './componentes/shared/modal/form-pedidos/form-pedidos.component';
import { FormNivelComponent } from './componentes/shared/modal/form-nivel/form-nivel.component';

import { Stripe } from "@awesome-cordova-plugins/stripe/ngx"
import { DepositoOperadorVendedorComponent } from './componentes/deposito-operador-vendedor/deposito-operador-vendedor.component';
import { FormProductoPrecioComponent } from './componentes/shared/modal/form-producto-precio/form-producto-precio.component';
import { FormOperacionComponent } from './componentes/shared/modal/form-operacion/form-operacion.component';
import { VendedorListaComponent } from './componentes/vendedor-lista/vendedor-lista.component';
import { HomeMembresiaVisitanteComponent } from './componentes/shared/home-membresia-visitante/home-membresia-visitante.component';
import { FormVideoMuestraComponent } from './componentes/shared/form-video-muestra/form-video-muestra.component';
import { HomePage } from './pages/home/home.page';
import { RouterService } from './services/router.service';

import { JwPaginationModule } from 'jw-angular-pagination';
import { FormBilleteraComponent } from './componentes/shared/modal/form-billetera/form-billetera.component';
import { CarritoPage } from './pages/carrito/carrito.page';
import { FormTdcComponent } from './componentes/shared/modal/form-tdc/form-tdc.component';
import { PerfilPage } from './pages/perfil/perfil.page';
import { OperadorAlmacenVendedorComponent } from './componentes/operador-almacen-vendedor/operador-almacen-vendedor.component';

import {IvyCarouselModule} from 'angular-responsive-carousel';
import { ProductoTipoUsuarioComponent } from './componentes/producto-tipo-usuario/producto-tipo-usuario.component';
import { ProductoNuevoComponent } from './componentes/producto-nuevo/producto-nuevo.component';
import { ProductoUsuarioComponent } from './componentes/producto-usuario/producto-usuario.component';
import { DeseosUsuarioComponent } from './componentes/deseos-usuario/deseos-usuario.component';
import { PipeServicesPipe } from './pipes/pipe-services.pipe';
import { ModalProductosComponent } from './componentes/modal-productos/modal-productos.component';
import { FormGaleriaComponent } from './componentes/shared/modal/form-galeria/form-galeria.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormPagoMembresiaComponent } from './componentes/shared/modal/form-pago-membresia/form-pago-membresia.component';


@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
     ModalLoginComponent,
      FormRegistroMiembroComponent,
      FormLoginUsuariosComponent,
      FormRegistroProveedorComponent,
      FormLoginProveedorComponent,
      OfertarModalComponent,
      FormMsgComponent,
      FormProductoUsuarioComponent,
      FormPedidosComponent,
      FormNivelComponent,
      DepositoOperadorVendedorComponent,
      FormProductoPrecioComponent,
      FormOperacionComponent,
      VendedorListaComponent,
      HomeMembresiaVisitanteComponent,
      FormVideoMuestraComponent,
      HomePage,
      FormBilleteraComponent,
      FormTdcComponent,
      PerfilPage,
      OperadorAlmacenVendedorComponent,
      ProductoTipoUsuarioComponent,
      ProductoNuevoComponent,
      ProductoUsuarioComponent,
      DeseosUsuarioComponent,
      PipeServicesPipe,
      ModalProductosComponent,
      FormGaleriaComponent,
      FormPagoMembresiaComponent],
  imports: [
    APP_ROUTING,
    BrowserModule,
    IonicModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(()=> initializeApp(environment.firebase)),
    provideStorage(()=> getStorage()),
    AngularFireAuthModule,
    HttpClientModule,
    AngularFirestoreModule,
    SwiperModule,
    RouterModule,
    JwPaginationModule,
    /* TooltipsModule.forRoot(), */
    BrowserAnimationsModule,
    IvyCarouselModule,
    ImageCropperModule
  ],
  exports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    /* AuthGuardServicesService,
    { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } }, */
    NetworkInterface,
    skServices,
    Stripe,
    PipeServicesPipe,
    RouterService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
