import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DepositoOperadorVendedorComponent } from './componentes/deposito-operador-vendedor/deposito-operador-vendedor.component';
import { DeseosUsuarioComponent } from './componentes/deseos-usuario/deseos-usuario.component';
import { OperadorAlmacenVendedorComponent } from './componentes/operador-almacen-vendedor/operador-almacen-vendedor.component';
import { ProductoNuevoComponent } from './componentes/producto-nuevo/producto-nuevo.component';
import { ProductoTipoUsuarioComponent } from './componentes/producto-tipo-usuario/producto-tipo-usuario.component';
import { ProductoUsuarioComponent } from './componentes/producto-usuario/producto-usuario.component';
import { HomeMembresiaVisitanteComponent } from './componentes/shared/home-membresia-visitante/home-membresia-visitante.component';
import { VendedorListaComponent } from './componentes/vendedor-lista/vendedor-lista.component';
import { HomePage } from './pages/home/home.page';
import { CanactivateServicesService } from './services/canactivate-services.service';

const RUTAS: Routes = [
  { path: 'deposito_operador_vendedor', component: DepositoOperadorVendedorComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'lista_operador_vendedor', component: VendedorListaComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'membresia_visitante', component: HomeMembresiaVisitanteComponent, data: {permiso: '',menu:''}},
  { path: 'home', component: HomePage,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'operador_almacen_vendedor', component: OperadorAlmacenVendedorComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'producto_tipo_usuario', component: ProductoTipoUsuarioComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'producto_nuevo', component: ProductoNuevoComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'producto_usuario', component: ProductoUsuarioComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  { path: 'deseos_usuario', component: DeseosUsuarioComponent,canActivate : [CanactivateServicesService], data: {permiso: '',menu:''}},
  {
    path: '404',
    loadChildren: () =>
      import('../app/pages/data-no-found/data-no-found.module').then(
        (m) => m.DataNoFoundPageModule
      ),
  },
  /* {
    path: 'inicio',
    loadChildren: () =>
      import('../app/pages/usuarios-market/usuarios-market.module').then(
        (m) => m.UsuariosMarketPageModule
      ),
    canActivate: [CanactivateServicesService],
  }, */
  {
    path: 'search',
    loadChildren: () =>
      import('../app/pages/vendor-page/vendor-page.module').then(
        (m) => m.VendorPageModule
      ),
      canActivate: [CanactivateServicesService],
  },
  /* {
    path: 'home',
    loadChildren: () =>
      import('../app/pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
      canActivate: [CanactivateServicesService],
  }, */
  {
    path: 'perfil',
    loadChildren: () =>
      import('../app/pages/perfil/perfil.module').then(
        (m) => m.PerfilPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'notificaciones',
    loadChildren: () =>
      import('../app/pages/notificaciones/notificaciones.module').then(
        (m) => m.NotificacionesPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('../app/pages/chat/chat.module').then(
        (m) => m.ChatPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'proveedor',
    loadChildren: () =>
      import('../app/pages/perfil-show/perfil-show.module').then(
        (m) => m.PerfilShowPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'pedidos',
    loadChildren: () =>
      import('../app/pages/pedidos/pedidos.module').then(
        (m) => m.PedidosPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'producto',
    loadChildren: () =>
      import('../app/pages/show-productos/show-productos.module').then(
        (m) => m.ShowProductosPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'carrito',
    loadChildren: () =>
      import('../app/pages/carrito-venta/carrito-venta.module').then(
        (m) => m.CarritoVentaPageModule
      ),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'market',
    loadChildren: () =>
      import('../app/pages/inicio/inicio.module').then((m) => m.InicioModule),
    canActivate: [CanactivateServicesService],
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('../app/pages/subscripcion/subscripcion.module').then((m) => m.SubscripcionPageModule),
      canActivate: [CanactivateServicesService],
  },
  {
    path: '',
    loadChildren: () =>
      import('../app/pages/login/login.module').then((m) => m.LoginPageModule),
  },

  ////////////////////////////////RUTAS COMPONENTES UNIDAD INICIALES////////////////////////////////////////
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

@NgModule({
  imports:[RouterModule.forRoot(RUTAS)],
  exports:[RouterModule]
})

/* export const APP_ROUTING = RouterModule.forRoot(RUTAS, {
  useHash: false,
  relativeLinkResolution: 'legacy',
}); */

export class APP_ROUTING {}
